<template>
  <main
    class="color-primary h-100 w-100"
  >
    <section v-if="flight" class="tourism-container">
      <div class="row m-0">
        <div class="col p-b-0">
          <h4>{{ $t('FLIGHTS.ORDER_DETAILS') }}</h4>
        </div>
      </div>
      <div class="row m-t-0">
        <div class="col-md-12">
          <div class="bg-white p5 bd-radius">
            <div class="d-flex justify-content-between w-100">
              <div class="w-100 px10">
                <label for="email">{{ $t('FLIGHTS.EMAIL') }}</label>
                <input id="email" type="text" class="input bd-radius" :value="email" disabled />
              </div>
              <div class="w-100 px10">
                <label for="phone">{{ $t('FLIGHTS.PHONE') }}</label>
                <input id="phone" type="text" class="input bd-radius" :value="phone" disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-t-0">
        <div class="col-md-9">
          <div class="bg-white p5 bd-radius">
            <div class="d-flex align-items-center flex-wrap m-t-5">
              <h4 class="mx5 m-b-10">{{ $t('FLIGHTS.TRIP_SUMMARY') }}</h4>
              <TripSummary :flight="flight" class="m-b-10"></TripSummary>
            </div>
            <div class="row m-t-5">
              <div v-if="flight.to_routes && flight.to_routes.length" class="col-md-4">
                <FligntTicket :routes="flight.to_routes"></FligntTicket>
              </div>
              <div v-if="flight.return_routes && flight.return_routes.length" class="col-md-4">
                <FligntTicket :routes="flight.return_routes"></FligntTicket>
              </div>
              <div class="col-md-4">
                <BaggageInfo :baglimit="flight.baglimit" :bagsprice="flight.bagsprice"></BaggageInfo>
                <CovideNotify class="m-t-15"></CovideNotify>
              </div>
            </div>
            <div v-if="flight.return_routes && flight.return_routes.length" class="row m-t-0 m-b-5">
              <div class="col-md-8 fs14 text-center">
                {{ flight.nights_in_dest }} {{ $t( "FLIGHTS.NIGHTS_IN" ) }} {{ flight.to.city_name }} <b>{{ $t('FLIGHTS.THEN_DEPART_FROM') }}</b> <b>{{ firstReturnRoute.from.airport_name || firstReturnRoute.from.city_name }} ({{ firstReturnRoute.from.airport_code }})</b>
              </div>
            </div>
          </div>

          <div v-if="passengers_data && passengers_data.length">
            <template v-for="(passenger, index) in passengers_data">
              <FlightPassengerInfo v-model="passengers_data[index]" :passenger_ind="index" :flight="flight" :view_mode="true" :show_edit_button="flights_order_status < get_status_num('confirmed_by_client')" :token="token"></FlightPassengerInfo>
            </template>
          </div>

        </div>
        <div class="col-md-3">
          <FlightResult :flight="flight" :passengers="passengers_data"></FlightResult>
        </div>
      </div>
      <div class="row m-t-0">
        <div class="col-md-12">
          <div class="d-flex bg-white p5 bd-radius">
            <button class="primary-btn primary-btn_sm" @click="bookOrderHandler">
              {{ $t('FLIGHTS.BOOK') }}
            </button>
            <button class="primary-btn primary-btn_sm bg-yellow" @click="openCancelOrderConfirmationModal">
              {{ $t('FLIGHTS.CANCEL') }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <ConfirmModal name="confirmCancelOrder" @confirmData="cancelOrderHandler">
      <template v-slot:modal-content>
        {{ $t('FLIGHTS.ARE_YOU_SURE') }}
      </template>
    </ConfirmModal>

    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { flightsService } from "@/services/flights.service";

    import FligntTicket from "@/components/tourism/tradeShow/FligntTicket.vue";
    import BaggageInfo from "@/components/tourism/tradeShow/BaggageInfo.vue";
    import CovideNotify from "@/components/tourism/tradeShow/CovideNotify.vue";
    import TripSummary from "@/components/tourism/tradeShow/TripSummary.vue";
    import FlightResult from "@/components/tourism/flight/FlightResult.vue";
    import FlightPassengerInfo from "@/components/tourism/flight/FlightPassengerInfo.vue";
    import ConfirmModal from "@/components/management/ConfirmModal.vue";

    export default {
        name: "FlightsOrder",
        components: {
            FligntTicket,
            BaggageInfo,
            CovideNotify,
            TripSummary,
            FlightResult,
            FlightPassengerInfo,
            ConfirmModal
        },
        data: () => {
            return {
                soft_check_flight_in_process: false,
                checkFlightsInterval: null,
                checkFlightsOrderStatusInterval: null,

                flights_check_result: null,
                flights_checked: false,
                price_change: false,
                flights_invalid: false,
                order_modal_name: 'order-modal',
                passengers_data: null
            };
        },
        computed: {
            ...mapState({
                load_order_details_in_process: (state) => state.flights.load_order_details_in_process,
                load_order_status_in_process: (state) => state.flights.load_order_status_in_process,
                cancel_flights_order_in_process: (state) => state.flights.cancel_flights_order_in_process,
                book_flights_order_in_process: (state) => state.flights.book_flights_order_in_process,
            }),
            ...mapGetters('flights', {
                flights_order_details: 'flights_order_details',
                flights_order_status: 'flights_order_status',
                get_status_num: 'get_status_num',
                get_status_name: 'get_status_name',
            }),
            loading() {
                return this.load_order_details_in_process
                    || this.cancel_flights_order_in_process
                    || this.book_flights_order_in_process;
            },
            order_id() {
                return this.$route.params.order_id;
            },
            token() {
                return this.$route.params.token;
            },
            phone() {
                let phone = null;

                if (this.flights_order_details) {
                    phone = this.flights_order_details.phone;
                }
                return phone;
            },
            email() {
                let email = null;

                if (this.flights_order_details) {
                    email = this.flights_order_details.email;
                }
                return email;
            },
            flight() {
                let flight = null;

                if (this.flights_order_details && this.flights_order_details.flight) {
                    flight = this.flights_order_details.flight;
                }

                // console.log(flight)
                return flight;
            },
            provider() {
                let provider = null;

                if (this.flight) {
                    provider = this.flight.provider;
                }
                return provider;
            },
            booking_token() {
                let booking_token = null;

                if (this.flight) {
                    booking_token = this.flight.booking_token;
                }
                return booking_token;
            },
            adults() {
                let adults = 0;

                if (this.passengers_data && this.passengers_data.length) {
                    adults = this.passengers_data.filter(pd => pd.category === 'adults').length;
                }

                return adults;
            },
            children() {
                let children = 0;

                if (this.passengers_data && this.passengers_data.length) {
                    children = this.passengers_data.filter(pd => pd.category === 'children').length;
                }

                return children;
            },
            infants() {
                let infants = 0;

                if (this.passengers_data && this.passengers_data.length) {
                    infants = this.passengers_data.filter(pd => pd.category === 'infants').length;
                }

                return infants;
            },
            session_id() {
                return this.flight.session_id || null;
            },
            adult_hold_bag_actual() {
                let adult_hold_bag = 0;
                if (this.passengers_data && this.passengers_data.length) {
                    adult_hold_bag = this.passengers_data.filter(p => p.category === 'adults').reduce((accumulator, pass) => {
                        return accumulator + +pass.hold_bag;
                    }, 0);
                }

                return adult_hold_bag;
            },
            adult_hand_bag_actual() {
                let adult_hand_bag = 0;
                if (this.passengers_data && this.passengers_data.length) {
                    adult_hand_bag = this.passengers_data.filter(p => p.category === 'adults').reduce((accumulator, pass) => {
                        return accumulator + +pass.hand_bag;
                    }, 0);
                }

                return adult_hand_bag;
            },
            child_hold_bag_actual() {
                let child_hold_bag = 0;
                if (this.passengers_data && this.passengers_data.length) {
                    child_hold_bag = this.passengers_data.filter(p => p.category === 'children').reduce((accumulator, pass) => {
                        return accumulator + +pass.hold_bag;
                    }, 0);
                }

                return child_hold_bag;
            },
            child_hand_bag_actual() {
                let child_hand_bag = 0;
                if (this.passengers_data && this.passengers_data.length) {
                    child_hand_bag = this.passengers_data.filter(p => p.category === 'children').reduce((accumulator, pass) => {
                        return accumulator + +pass.hand_bag;
                    }, 0);
                }

                return child_hand_bag;
            },
            firstReturnRoute() {
                return this.flight.return_routes && this.flight.return_routes.length ? this.flight.return_routes[0] : null;
            },
            status_label() {
                let status_label = '-';
                if (this.flights_order_status) {
                    status_label = this.$t('FLIGHTS.STATUS_' + this.get_status_name(this.flights_order_status).toString().toUpperCase());
                }

                return status_label;
            }
        },
        methods: {
            ...mapActions('flights', {
                fetchFlightsOrderDetails: 'fetchFlightsOrderDetails',
                fetchFlightsOrderStatus: 'fetchFlightsOrderStatus',
                updateFlightsOrderDetails: 'updateFlightsOrderDetails',
                cancelFlightsOrder: 'cancelFlightsOrder',
                initiateFlightsOrderPriceUpdate: 'initiateFlightsOrderPriceUpdate',
                bookFlightsOrder: 'bookFlightsOrder',
            }),
            ...mapActions("alert", {
                errorAlert: "error",
                infoAlert: "info",
            }),
            openCancelOrderConfirmationModal() {
                this.$modal.show('confirmCancelOrder');
            },
            async cancelOrderHandler() {
              await this.cancelFlightsOrder({
                  id: this.order_id,
                  token: this.token,
                  type: 'manually'
              }).then(
                (result) => {
                  this.redirectToOrderResultPage();
                  return result;
                },
                (error) => {
                  this.errorAlert(this.$t("FLIGHTS.ERROR"));
                  return error;
                });
            },
            async bookOrderHandler() {
              await this.bookFlightsOrder({
                  id: this.order_id,
                  token: this.token,
              }).then(
                (result) => {
                  return result;
                },
                (error) => {
                  return error;
                }
              );
            },
            async initCheckFlightProcess() {
                let obj = this;
                if (!obj.flights_order_details
                    || obj.checkFlightsInterval
                    || obj.flights_order_status > obj.get_status_num('confirmed_by_client')) {
                    return;
                }

                obj.checkFlightsInterval = setInterval(async () => {
                    await flightsService.checkFlight(
                        obj.provider,
                        obj.booking_token,
                        obj.adults,
                        obj.children,
                        obj.infants,
                        obj.adult_hold_bag_actual,
                        obj.adult_hand_bag_actual,
                        obj.child_hold_bag_actual,
                        obj.child_hand_bag_actual,
                        obj.session_id
                    ).then(
                        async (result) => {
                            // console.log(result);

                            if (result) {
                                obj.flights_check_result = result;

                                obj.flights_checked = result.flights_checked;
                                obj.price_change = result.price_change;
                                obj.flights_invalid = result.flights_invalid;

                                if (obj.flights_invalid) {
                                    obj.errorAlert(obj.$t("FLIGHTS.FLIGHT_IS_NOT_AVAILABLE"));
                                    obj.clearCheckFlightsInterval();
                                    // todo: cancel flights order here?
                                    await obj.cancelFlightsOrder({
                                        id: obj.order_id,
                                        type: 'auto',
                                        token: obj.token,
                                    }).then(
                                        (r) => {
                                            return r;
                                        },
                                        (e) => {
                                            return e;
                                        });
                                    // await obj.redirectBack();
                                }
                                // if (obj.price_change) {
                                    let changed_price = result.changed_price;
                                    let changed_bagsprice = result.changed_bagsprice;

                                    let current_price = obj.flight.price;
                                    let current_bagsprice = obj.flight.bagsprice;
                                    if (current_price.orig_price !== changed_price.orig_price
                                        || current_bagsprice.first_baggage !== changed_bagsprice.first_baggage
                                        || current_bagsprice.second_baggage !== changed_bagsprice.second_baggage
                                        || current_bagsprice.hand !== changed_bagsprice.hand) {

                                        obj.infoAlert(obj.$t("FLIGHTS.PRICE_CHANGED"));
                                        obj.updateFlightsOrderDetails({
                                            price: changed_price,
                                            bagsprice: changed_bagsprice,
                                        });

                                        obj.initiateFlightsOrderPriceUpdate({
                                            id: obj.order_id,
                                            token: obj.token,
                                        });
                                    }
                                // }
                            }
                            return result;
                        },
                        (error) => {
                            return error;
                        }
                    );

                }, 15 * 1000);
            },
            async initCheckFlightsOrderStatusProcess() {
                let obj = this;
                if (obj.flights_order_status > obj.get_status_num('paid')
                    || obj.checkFlightsOrderStatusInterval) {
                    return;
                }

                obj.checkFlightsOrderStatusInterval = setInterval(async () => {
                    await obj.fetchFlightsOrderStatus({
                        id: obj.order_id,
                        token: obj.token,
                    }).then(
                        async (result) => {
                            // console.log(result);

                            if (result && result.order && 'status' in result.order
                                && +result.order.status > obj.get_status_num('paid')) {

                                obj.clearCheckFlightsOrderStatusInterval();

                            }
                            return result;
                        },
                        (error) => {
                            return error;
                        }
                    );

                }, 15 * 1000);
            },
            async redirectToOrderResultPage() {
                await this.$router.push({
                    name: 'PublicFlightsOrderResult',
                    params: {
                        order_id: this.order_id,
                        token: this.token,
                    },
                });
            },
            async redirectToFlightsHome() {
                await this.$router.push({ name: 'FlightHome'});
            },
            async redirectBack() {
                await this.$router.back();
            },
            clearCheckFlightsInterval() {
                if (this.checkFlightsInterval) {
                    clearInterval(this.checkFlightsInterval);
                    this.checkFlightsInterval = null;
                }
            },
            clearCheckFlightsOrderStatusInterval() {
                if (this.checkFlightsOrderStatusInterval) {
                    clearInterval(this.checkFlightsOrderStatusInterval);
                    this.checkFlightsOrderStatusInterval = null;
                }
            },
        },
        async created() {
          await this.fetchFlightsOrderDetails({id: this.order_id, token: this.token});

          this.initCheckFlightProcess();
          this.initCheckFlightsOrderStatusProcess();
        },
        beforeUnmount() {
            this.clearCheckFlightsInterval();
            this.clearCheckFlightsOrderStatusInterval();
        },
        unmounted() {
            this.clearCheckFlightsInterval();
            this.clearCheckFlightsOrderStatusInterval();
        },
        destroyed() {
            this.clearCheckFlightsInterval();
            this.clearCheckFlightsOrderStatusInterval();
        },
        watch: {
            flights_order_details: {
                handler (newValue, oldValue) {
                    if (newValue) {
                        this.initCheckFlightProcess();
                    }

                        let passengers_data = null;

                        if (this.flights_order_details && this.flights_order_details.passengers) {
                            passengers_data = this.flights_order_details.passengers;
                            passengers_data = passengers_data.map(pd => {return {...pd, order_id: this.order_id}});
                        }

                        // console.log(flight)
                        this.passengers_data = passengers_data;
                },
                deep: true,
            },
            flights_order_status: {
                handler (newValue, oldValue) {
                    if (!newValue) {
                        return;
                    }
                    if (!this.load_order_details_in_process && newValue !== this.flights_order_details.status) {
                        this.fetchFlightsOrderDetails({id: this.order_id, token: this.token});
                    }
                    if (newValue > this.get_status_num('created')) {
                        this.clearCheckFlightsInterval();
                        this.clearCheckFlightsOrderStatusInterval();
                        this.redirectToOrderResultPage();
                    }
                },
                deep: true,
            },
        }
    }
</script>

<style lang="scss">
  .trip-summary {
    width: 100% !important;
    .flight-company {
      width: 100% !important;
      text-align: center !important;
      .airline-logo {
        margin: 0 auto !important;
      }
    }
  }
</style>
<style lang="scss" scoped>
  @import "@/assets/scss/tourism.scss";
  .order-btn {
    margin: 0;
    margin-top: 15px;
  }
  .small-loader {
    width: 25px;
    height: 25px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
</style>